// @import "bower_components/bootstrap/scss/_variables.scss";

$gray-dark: #000000;
$body-color: #000000;

$navbar-height: 85px;
$section-padding: 80px;
$primary-color: #D89C09;

// Bootstrap

$font-family-sans-serif: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       StainzCalibre, $font-family-sans-serif !default;

$font-weight-bold: 500 !default;
$font-size-base: 1.4rem !default;
$line-height-base: 1.2 !default;

$font-size-h1: 4rem !default;
$font-size-h2: 3rem !default;
$font-size-h3: 1.75rem !default;
$font-size-h4: 1.5rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;

$spacer: 1rem !default;
$headings-margin-bottom: $spacer * 2 !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1024px,
  xl: 1300px
) !default;
