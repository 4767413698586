$icon-font-path: '../fonts/';

@import "./fonts";
@import "./variables";

// bower:scss
@import "bower_components/bootstrap/scss/bootstrap.scss";
// endbower

@import "./nav_and_header";
@import "./navbar";
@import "./components";
@import "./vendor";
@import "./folding_cube";

body {
  @include media-breakpoint-up(md) {
    padding-top: $section-padding / 2;
  }
}

a.download {
  text-decoration: none;
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 1px solid #000;
  color: #000;
  line-height: 1.5;

  &:hover {
    font-weight: 500;
  }
}

.stainz--section {
  // padding: $section-padding 0;
  background-color: white;
}

.stainz--section-veranstaltungen {
  p, span { font-size: 104%; }
}

.col *:last-child {
  margin-bottom: 0;
}

.stainz--section .row {
  margin-top: $section-padding;
  margin-bottom: $section-padding;
}

.stainz--footer {
  border-top: 4px solid $primary-color;
  padding-top: 15px;
  padding-bottom: 30px;
  font-size: 1.1rem;
  line-height: 18px;

  .icons {
    @include media-breakpoint-up(md) {
      text-align: right;
    }
    @include media-breakpoint-down(md) {
      margin-top: 10px;
    }
  }
}

i.icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: 25px 25px;
  background-repeat: no-repeat;
  margin-left: 4px;

  &.facebook {
    background-image: url('/images/facebook.png');
  }

  &.instagram {
    background-image: url('/images/instagram.png');
  }
}

.lightwidget-row {
  margin-left: -30px;
  margin-right: -30px;
}

@import "./browsers";