@import "./variables";
@import "./mixins";

.stainz--top-nav {
  position: sticky;
  .no-csspositionsticky & {
    position: fixed;
  }

  z-index: 2;
  top: 0px;
  left: 0;
  right: 0;
  min-height: $navbar-height;

  @include media-breakpoint-down(md) {
    position: fixed;
    top: 0;
    padding-top: 20px;
    background-color: transparent;
    transition: background-color .2s;

    &.expanded, &.sps--blw {
      background-color: white;
    }

    a.navbar-brand {
      margin-bottom: 20px;
    }
  }

  @include media-breakpoint-up(md) {
    transition: background-color .2s;

    &.scrolling {
      background-color: white;
    }
  }
}

a.navbar-brand {
  text-indent: -9999px;
  background: url('../images/logo.svg');
  background-repeat: no-repeat;
  height: 86px * 1.4;
  width: 80px * 1.4;
  transition: height .2s, width .2s;
  margin: auto;
  
  @include media-breakpoint-up(md) {
    position: absolute;
    left: 0;
    right: 0;
  }

  @include sps--blw {
    height: 86px * 0.8;
    width: 80px * 0.8;
  }
}

.stainz--top-nav .navbar {
  align-items: start;

  a.nav-link {
    color: black;
    font-size: 1.15rem;
    font-weight: 500;
    border-top: 2px solid black;

    @at-root .stainz--top-nav .navbar .active > a.nav-link {
      border-top: 2px solid $primary-color;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    @include media-breakpoint-up(md) {
      padding: 0;
      padding-top: 5px;
      margin-right: 20px;
    }
  }

  li:last-child a {
    margin-right: 0;
  }

  .collapse.navbar-collapse {
    justify-content: space-between;
  }
}

.scroll-down-hint {
  display: none;
  color: black;
  text-decoration: none !important;
}
@media (min-height: 700px) {
  .stainz--header.full-page .scroll-down-hint {
    position: absolute;
    display: block;
    bottom: 40px;
    height: 30px;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 1;
    transition: opacity .4s;

    &.sps--blw {
      opacity: 0;
    }
  }
}

.stainz--header {
  position: relative;
  min-height: 600px;
  padding-top: #{$section-padding} + 140px;
  padding-bottom: $section-padding;
  background: transparent;

  @include media-breakpoint-up(md) {
    margin-top: -$navbar-height - $section-padding / 2;  
  }

  &.full-page {
    padding-top: calc(#{$section-padding} + 10vh + 100px);
    min-height: 100vh;
    background: $primary-color;
  }

  blockquote {
    p {
      font-size: 3rem;
      line-height: 3.1rem;

      @include media-breakpoint-up(lg) {
        font-size: 4rem;
        line-height: 3.6rem;
      }
    }

    footer {
      font-weight: 500;
      font-size: 1.15rem;
    }
  }
}