$height: 20px;
$thickness: 2px;

/* Icon 3 */
.mary-toggler-icon {
  width: $height + 10px;
  height: $height;
  position: relative;
  top: 0;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

.mary-toggler-icon span {
  display: block;
  position: absolute;
  height: $thickness;
  width: 100%;
  background: #000;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.navbar-toggler:not(.collapsed) .mary-toggler-icon {
  span:nth-child(1) {
    top: $height / 2;
    width: 0%;
    left: 50%;
  }

  span:nth-child(2) {
    top: $height / 2;
    transform: rotate(45deg);
  }

  span:nth-child(3) {
    top: $height / 2;
    transform: rotate(-45deg);
  }

  span:nth-child(4) {
    top: $height / 2;
    width: 0%;
    left: 50%;
  }
}

.navbar-toggler.collapsed {
  .mary-toggler-icon {
    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2),
    span:nth-child(3) {
      top: $height / 2;
    }

    span:nth-child(4) {
      top: $height;
    }
  }
}
