@import "./mixins";

blockquote {
  p {
    font-weight: bold;
    @include media-breakpoint-up(lg) {
      white-space: pre-line;
    }

    &:before {
      content: "\201C";
      display: inline;
      position: relative;
      left: 8px;
    }
    &:after {
      content: "\201D";
      display: inline;
      position: relative;
      right: 8px;
    }
  }

  footer {
    &:before {
      content: " ";
      display: block;
      margin: 20px auto;
      border-top: 1px solid black;
      max-width: 55px;
    }
  }
}

.row--image {
  text-align: center;
  a {
    margin: auto;
    display: block;
  }
}

// p, h1, h2, h3, h4, h5, h6 {
//   @include media-breakpoint-up(lg) {
//     white-space: pre-line;
//   }
// }

.text--light {
  font-weight: 200;
}

.stainz--lined-header {
  font-weight: 200;
  font-size: 1.2rem;
  display: block;
  margin: 0;

  span:before, span:after {
    display: inline-block;
    width: 40px;
    border-top: 1px solid black;
    content: " ";
    height: 8px;
  }

  @include media-breakpoint-up(md) {
    span:before, span:after { width: 180px; }
  }

  span:before {
    margin-right: 20px;
  }

  span:after {
    margin-left: 20px;
  }
}

.stainz--event-list-item {
  margin: #{$spacer * 3} 0;

  p.date {
    // font-size: 1.2rem;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
  }

  h2 {
    margin-bottom: 15px;
  }

  p {
    font-weight: 200;
    font-size: 1.2rem;
  }
}
